/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Link from '../Link';
import { trackCustom } from '../../helpers/facebookanalytics';
const Footer = ({
  hideFollowUs = false,
  hideAbout = false,
  hideStores = false,
}) => (
  <footer>
    <hr />
    <div className="container">
      <div className="row footer">
        <div className="col-md-3">
          <Link to="/" className="navbar-brand">
            <img loading="lazy" src="./assets/images/logo-o.svg" alt="" />
          </Link>
          <li>مدربك في جيبك</li>
        </div>
        {hideAbout ? null : (
          <div className="col-md-2">
            <p className="bold">عن الكوتش</p>
            <ul>
              <li itemScope itemType="http://schema.org/SiteNavigationElement">
                <a itemProp="url" href="https://blog.elcoach.me/articles/">
                  <span itemProp="name">المدونة</span>
                </a>
              </li>
              <li itemScope itemType="http://schema.org/SiteNavigationElement">
                <a itemProp="url" href="/nutritionsystem">
                  <span itemProp="name">نظام التغذية</span>
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="col-md-2">
          <p className="bold">الخصوصية</p>
          <ul>
            <li itemScope itemType="http://schema.org/SiteNavigationElement">
              <a itemProp="url" href="/privacy">
                <span itemProp="name">سياسة الخصوصية</span>
              </a>
            </li>
            <li itemScope itemType="http://schema.org/SiteNavigationElement">
              <a itemProp="url" href="/termsandconditions">
                <span itemProp="name">شروط الاستخدام</span>
              </a>
            </li>
          </ul>
        </div>
        {hideFollowUs ? null : (
          <div className="col-md-2">
            <p className="bold">تابعنا</p>
            <a
              href="https://www.facebook.com/ElCoachfit"
              target="_blank"
              // rel="noreferrer"
            >
              <i className="fa fa-facebook fa-lg" />
            </a>
            <a
              href="https://twitter.com/ElCoachfit"
              target="_blank"
              // rel="noreferrer"
            >
              <i className="fa fa-twitter fa-lg" />
            </a>
            <a
              href="https://www.instagram.com/ElCoachfit/"
              target="_blank"
              // rel="noreferrer"
            >
              <i className="fa fa-instagram fa-lg" />
            </a>
          </div>
        )}
        {hideStores ? null : (
          <div className="col-md-3 get-app">
            <a
              href="https://play.google.com/store/apps/details?id=com.elcoach.me"
              target="_blank"
              onClick={() => {
                trackCustom('WebInstall', { type: 'android' });
              }}
              // rel="noreferrer"
            >
              <img
                src="https://cdn.elcoach.me/assets/website/images/google-play.png"
                alt=""
              />
            </a>
            <a
              href="https://apps.apple.com/app/apple-store/id1431776088"
              target="_blank"
              onClick={() => {
                trackCustom('WebInstall', { type: 'ios' });
              }}
              // rel="noreferrer"
            >
              <img
                src="https://cdn.elcoach.me/assets/website/images/app-store.png"
                alt=""
              />
            </a>
          </div>
        )}
      </div>
    </div>
  </footer>
);

export default Footer;
