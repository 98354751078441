/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import Navigation from '../Navigation';
import { getItemFromLocalStorage } from '../../helpers/local_storage_manger';

const Header = ({
  bgColor = 'transparent',
  isDarkHeader = false,
  hideBlog = false,
  hideAuthButton = false,
  hideLanguages = false,
  hideMenu = false,
}) => (
  <div className="header" style={{ backgroundColor: '#FFF0EB' }}>
    <nav
      className={`navbar navbar-expand-lg ${
        bgColor == 'transparent' || !isDarkHeader
          ? 'navbar-light'
          : 'navbar-dark'
      } svg-img-10`}
    >
      {hideMenu ? (
        <span />
      ) : (
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      )}
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          {hideAuthButton ? null : (
            <li
              className="nav-item user-nav"
              itemScope
              itemType="http://schema.org/SiteNavigationElement"
            >
              {/* <button 
              className="nav-link"
              // href= {`${getItemFromLocalStorage('profileInfo') ? '/profile' : '/login'}`}
              onClick={ () => {
                !getItemFromLocalStorage('profileInfo') ?  window.location = '/login' : null
              }}
              style={{
                color: isDarkHeader ? 'black' : 'white',
              }}
              disabled={!getItemFromLocalStorage('profileInfo')}
            > */}
              <a
                itemProp="url"
                className="nav-link clickablePointer active-user-nav"
                // onClick={() => {
                //   !getItemFromLocalStorage('profileInfo')
                //     ? (window.location = '/login')
                //     : (window.location = './profile');
                // }}
                href={!getItemFromLocalStorage('AC') ? '/login' : '/profile'}
                style={{
                  color: isDarkHeader ? 'black' : 'white',
                }}
              >
                <span itemProp="name">
                  {getItemFromLocalStorage('profileInfo') && (
                    <svg
                      className="userHeaderIcon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 20"
                      fill={isDarkHeader ? '#FF6D38' : '#FF6D38'}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.25 11.875C11.1289 11.875 10.5898 12.5 8.75 12.5C6.91016 12.5 6.375 11.875 5.25 11.875C2.35156 11.875 0 14.2266 0 17.125V18.125C0 19.1602 0.839844 20 1.875 20H15.625C16.6602 20 17.5 19.1602 17.5 18.125V17.125C17.5 14.2266 15.1484 11.875 12.25 11.875ZM15.625 18.125H1.875V17.125C1.875 15.2656 3.39062 13.75 5.25 13.75C5.82031 13.75 6.74609 14.375 8.75 14.375C10.7695 14.375 11.6758 13.75 12.25 13.75C14.1094 13.75 15.625 15.2656 15.625 17.125V18.125ZM8.75 11.25C11.8555 11.25 14.375 8.73047 14.375 5.625C14.375 2.51953 11.8555 0 8.75 0C5.64453 0 3.125 2.51953 3.125 5.625C3.125 8.73047 5.64453 11.25 8.75 11.25ZM8.75 1.875C10.8164 1.875 12.5 3.55859 12.5 5.625C12.5 7.69141 10.8164 9.375 8.75 9.375C6.68359 9.375 5 7.69141 5 5.625C5 3.55859 6.68359 1.875 8.75 1.875Z" />
                    </svg>
                  )}
                  {getItemFromLocalStorage('profileInfo')
                    ? getItemFromLocalStorage('profileInfo').name
                    : 'تسجيل الدخول'}
                </span>
              </a>
              {/* </button> */}
            </li>
          )}
          {hideBlog ? null : (
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://blog.elcoach.me/articles/"
                target="_blank"
                style={{
                  color: isDarkHeader ? 'black' : 'white',
                }}
              >
                المدونة
              </a>
            </li>
          )}
          {hideLanguages ? null : (
            <li>
              <div class="dropdown dropdown-ar">
                <button
                  class="btn btn-secondary dropdown-toggle ltr"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="https://s3.eu-west-1.amazonaws.com/cdn.elcoach.me/assets/icons/ksaflag.svg"
                    width="24px"
                    height="16px"
                  ></img>
                  عربي
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="https://elcoach.me/en">
                    English
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWIC-XJ_OLOulX7EyjTVSSSAbt8Q1b1ahAUQ&usqp=CAU"
                      width="24px"
                      height="16px"
                    ></img>
                  </a>
                  <hr />
                  <a class="dropdown-item" href="https://elcoach.me/">
                    عربي
                    <img
                      src="https://s3.eu-west-1.amazonaws.com/cdn.elcoach.me/assets/icons/ksaflag.svg"
                      width="24px"
                      height="16px"
                    ></img>
                  </a>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div
        className="header-logo"
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          width: '20vw',
        }}
      >
        {/* <span>
          <svg
            version="1.1"
            id="Layer_1"
            viewBox="0 0 319.59 106.38"
            style={{ fill: '#A0E50B', top: -10 }}
            className="st0"
          >
            <g>
              <path
                className="st0"
                d="M78.18,107H52.59L87,0h25.59L78.18,107z M130.18,107h-25.59L139,0h25.59L130.18,107z M26.18,107H0.59L35,0
                        h25.59L26.18,107z M181.61,107h-26.02L190.57,0h26.02L181.61,107z M233.18,107h-25.59L242,0h25.59L233.18,107z"
              />
              <path
                className="st0"
                d="M233.18,107h-25.59L242,0h25.59L233.18,107z M284.61,107h-26.02L293.57,0h26.02L284.61,107z"
              />
            </g>
          </svg>
        </span> */}
        <Link to="/" className="navbar-brand">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="136"
            height="40"
            viewBox="0 0 136 40"
          >
            <g transform="translate(-1379 -24) translate(396 24)">
              <path
                fill={!isDarkHeader ? '#FF6D38' : '#fff'}
                d="M44.117 26.74h-5.41l-2.245 6.566h5.352l-.002.008h6.937l6.64-19.738h-6.861l-4.41 13.164zm28.56-6.154l-.009.017c-.222.509-.522.955-.903 1.335-.385.384-.835.689-1.352.911-.516.223-1.068.334-1.655.334s-1.14-.111-1.656-.334c-.303-.13-.582-.293-.84-.48-.18-.131-.353-.273-.511-.431-.385-.385-.689-.836-.911-1.352-.224-.516-.334-1.068-.334-1.655 0-.588.11-1.14.334-1.656.222-.516.526-.967.91-1.352.385-.384.836-.688 1.352-.91.517-.224 1.069-.335 1.656-.335s1.139.111 1.655.335c.517.222.967.526 1.352.91.384.385.688.836.912 1.352.222.516.334 1.068.334 1.656 0 .587-.112 1.139-.334 1.655zM119.09 0l-7.347 21.89-2.956.01 3.695-11.014H87.584l1.326-3.947 18.053.001L109.292 0H84.155L80.12 12.016l5.85 5.334h17.5l-1.528 4.55H78.97c.095-.326.175-.656.237-.992l.019-.102.012-.066.023-.137c.085-.546.129-1.103.129-1.672 0-1.458-.279-2.835-.836-4.131-.557-1.296-1.316-2.426-2.278-3.387-.962-.962-2.09-1.721-3.387-2.279-1.296-.557-2.673-.835-4.131-.835-1.458 0-2.835.278-4.131.835-1.296.558-2.426 1.317-3.387 2.279-.962.961-1.721 2.09-2.278 3.387-.558 1.296-.836 2.673-.836 4.13 0 1.458.278 2.836.836 4.132.557 1.296 1.316 2.425 2.278 3.387.961.962 2.09 1.721 3.387 2.278 1.296.556 2.673.835 4.13.835.072 0 .142-.003.213-.005l-1.841 5.455 5.23 4.759 3.81-11.304 40.212.008L125.934 0h-6.844zm9.74 0l-9.565 28.475h6.847L135.675 0h-6.845zM50.012 3.211L47.136.336l-4.312 4.313 3.243 3.243 2.875-2.876 2.875 2.876 4.313-4.313L52.887.336 50.012 3.21zM38.115 28.475l5.002-14.899h-6.705l-2.79 8.325h-2.876l2.793-8.325h-6.843L23.9 21.901h-2.469l2.79-8.325h-6.838l-6.03 17.865H7.27l5.993-17.865H6.424L0 32.656l6.011 5.35 10.005.009 3.205-9.548 18.894.008zM24.635 7.892L21.39 4.649 25.704.336l2.875 2.875L31.454.336l2.875 2.875L37.204.336l3.243 3.243-4.313 4.313-2.875-2.876-2.875 2.876-2.875-2.876-2.875 2.876z"
                transform="translate(983)"
              />
            </g>
          </svg>
        </Link>
      </div>
    </nav>
  </div>
);

Header.propTypes = {
  bgColor: PropTypes.string,
  isDarkHeader: PropTypes.bool,
};

Header.defaultProps = {
  bgColor: 'transparent',
  isDarkHeader: false,
};

export default Header;
